import React from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CustomInput, Button } from 'sqrl-design-system'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
})

const ForgotPasswordForm = () => (
  <div>
    <Formik
      initialValues={{}}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'forgot-password', ...values })
        })
          .then(() => {
            alert('Success!')
            setSubmitting(false)
          })
          .catch(error => alert(error))
      }}
      render={props => (
        <Form
          className="form-forgot-password"
          name="forgot-password"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="forgot-password" />

          <Field
            className={
              props.touched.email && props.errors.email ? 'is-invalid' : ''
            }
            type="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            id="email"
            name="email"
            label="Email Address"
            component={CustomInput}
          />

          <Button
            isColor={props.isValid ? 'primary' : ''}
            className="is-rounded full-width"
            type="submit"
          >
            Reset Password
          </Button>
        </Form>
      )}
    />
  </div>
)

export default ForgotPasswordForm
