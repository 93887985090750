import React, { Component } from 'react'
import {
  FlatLogo,
  Section,
  Container,
  Card,
  CardHeader,
  CardContent
} from 'sqrl-design-system'
import { Link } from 'gatsby'
import ForgotPasswordForm from '../forms/forgotPassword'

class ForgotPassword extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Card
            style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <CardHeader className="forgot-password-header">
              <FlatLogo fill="#9013fe" style={{ marginRight: 5 }} />
              <span className="text-very-dark">|{'  '}Workforce</span>
            </CardHeader>
            <CardContent style={{ padding: 0 }}>
              <div className="forgot-password-wrapper">
                <h1>Forgot Your Password?</h1>
                <p className="forgot-password-cta">
                  Enter your email address below and we’ll get you back on
                  track.
                </p>
                <ForgotPasswordForm />
                <Link
                  style={{ display: 'inline-block', marginTop: 32 }}
                  to="/login"
                >
                  Back to Sign In
                </Link>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Section>
    )
  }
}

export default ForgotPassword
