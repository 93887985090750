import React from 'react'

import ForgotPassword from '../components/ForgotPassword/ForgotPassword'
import SEO from '../components/SEO'

export default () => (
  <div style={{ backgroundColor: '#f5f5f5' }}>
    <SEO title='Forgot Password' />
    <ForgotPassword />
  </div>
)
